import styled from 'styled-components'
import { font } from '../vars/font.style'
import { clamp } from '../utils/conversion.style'

export const textStylesConfig = {
  heading1: {
    size: {
      min: 36,
      max: 48,
    },
    lineHeight: 1,
  },
  heading2: {
    size: {
      min: 32,
      max: 40,
    },
    lineHeight: 1,
  },
  heading3: {
    size: {
      min: 24,
      max: 32,
    },
    lineHeight: 1,
  },
  heading4: {
    size: {
      min: 20,
      max: 24,
    },
    lineHeight: 1.5,
  },
  body: {
    size: {
      min: 16,
      max: 20,
    },
    lineHeight: 1.6,
  },
  bodySmall: {
    size: 16,
    lineHeight: 1.5,
  },
  bodyXSmall: {
    size: 12,
    lineHeight: 1.6666666667,
  },
}

export const getStyles = (style, props = {}) => `
  ${
    typeof style.size === 'object'
      ? clamp('font-size', style.size.min, style.size.max)
      : `font-size: ${style.size / 10}rem;`
  }
  font-family: ${props.secondary ? font.secondary.family : font.primary.family};
  font-weight: ${
    props.bold ? font.primary.weight.bold : font.primary.weight.regular
  };
  line-height: ${style.lineHeight};
  ${style.letterSpacing ? `letter-spacing: ${style.letterSpacing}em` : ''};
  ${props.uppercase ? 'text-transform: uppercase' : ''};
`

export const Heading1 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${props => getStyles(textStylesConfig.heading1, props)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading2 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${props => getStyles(textStylesConfig.heading2, props)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading3 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${props => getStyles(textStylesConfig.heading3, props)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading4 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${props => getStyles(textStylesConfig.heading4, props)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBody = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${props => getStyles(textStylesConfig.body, props)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodySmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${props => getStyles(textStylesConfig.bodySmall, props)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodyXSmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${props => getStyles(textStylesConfig.bodyXSmall, props)}
  color: ${props => (props.color ? props.color : undefined)};
`
