export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}

export function setScreenReaderOnlyStyles() {
  return `
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  `
}

export function getFilteredEvents(data) {
  const upcoming = []
  const past = []
  const today = Date.now().toString()

  data.forEach(date => {
    const datetime = new Date(date.startDateString).valueOf()

    if (today > datetime) {
      if (date.showInPastEvents) {
        past.push(date)
      }
    } else {
      upcoming.push(date)
    }
  })

  return {
    upcoming: upcoming.reverse(),
    past,
  }
}
