import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PageTransitionLink from '../PageTransitionLink'
import Container from '../Container'
import MenuToggle from '../MenuToggle'
import Spacer from '../Spacer'
import { useStore } from '../../Store'
import { Heading4, Heading3 } from '../TextStyles'
import {
  Donate,
  DonateText,
  HeaderContainer,
  HeaderDivider,
  HeaderLogo,
  HeaderMain,
  HeaderMenu,
  HeaderMenuItem,
  HeaderNav,
  HeaderWrapper,
  MobileMenu,
  MobileMenuItem,
  MobileNav,
  MobileNavMain,
  MobileNavOverlay,
} from './index.style'
import Socials from '../Socials'
import IconArrowRight from '../svgs/IconArrowRight'
import { colors } from '@styles/vars/colors.style'

const Header = ({ pathname }) => {
  const [store] = useStore()
  const { mobileNavIsOpen } = store
  const { contentfulGlobals } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        donateUrl
        headerItems {
          ... on ContentfulAbout {
            seoTitle
            slug
          }
          ... on ContentfulOurWork {
            seoTitle
            slug
          }
          ... on ContentfulContact {
            seoTitle
            slug
          }
          ... on ContentfulEvents {
            seoTitle
            slug
          }
          ... on ContentfulResources {
            seoTitle
            slug
          }
        }
      }
    }
  `)
  const { donateUrl, headerItems } = contentfulGlobals
  const menuItems = headerItems.map(item => ({
    slug: item.slug,
    text: item.seoTitle,
  }))

  return (
    <HeaderContainer>
      <MobileNavOverlay open={mobileNavIsOpen} />

      <HeaderWrapper>
        <Container>
          <HeaderMain>
            <HeaderLogo>
              <PageTransitionLink to="">Home</PageTransitionLink>
            </HeaderLogo>

            <MenuToggle />

            <HeaderNav>
              <HeaderMenu>
                {React.Children.toArray(
                  menuItems.map(item => (
                    <HeaderMenuItem active={pathname.indexOf(item.slug) > -1}>
                      <PageTransitionLink to={item.slug}>
                        <Heading4 secondary uppercase>
                          {item.text}
                        </Heading4>
                      </PageTransitionLink>
                    </HeaderMenuItem>
                  ))
                )}
              </HeaderMenu>

              <HeaderDivider />

              <Socials />
            </HeaderNav>
          </HeaderMain>
        </Container>
      </HeaderWrapper>

      <MobileNav open={mobileNavIsOpen}>
        <MobileNavMain>
          <MobileMenu>
            {React.Children.toArray(
              menuItems.map(item => (
                <MobileMenuItem>
                  <PageTransitionLink to={item.slug}>
                    <Heading3 secondary uppercase>
                      {item.text}
                    </Heading3>
                  </PageTransitionLink>
                </MobileMenuItem>
              ))
            )}
          </MobileMenu>
        </MobileNavMain>

        <Spacer size={40} />

        <Socials />

        <Donate href={donateUrl}>
          <DonateText>Donate</DonateText>{' '}
          <IconArrowRight fill={colors.yellow} />
        </Donate>
      </MobileNav>
    </HeaderContainer>
  )
}

export default Header
