import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { SocialsItem, SocialsMain } from './index.style'
import LogoFacebook from '../svgs/LogoFacebook'
import LogoTwitter from '../svgs/LogoTwitter'
import LogoInstagram from '../svgs/LogoInstagram'

const Socials = ({ theme = 'dark' }) => {
  const {
    contentfulGlobals: { facebookUrl, twitterUrl, instagramUrl },
  } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        facebookUrl
        twitterUrl
        instagramUrl
      }
    }
  `)

  return (
    <SocialsMain theme={theme}>
      <SocialsItem href={facebookUrl}>
        <LogoFacebook />
      </SocialsItem>

      <SocialsItem href={twitterUrl}>
        <LogoTwitter />
      </SocialsItem>

      <SocialsItem href={instagramUrl}>
        <LogoInstagram />
      </SocialsItem>
    </SocialsMain>
  )
}

Socials.propTypes = {
  theme: PropTypes.oneOf(['dark', 'light']),
}

export default Socials
