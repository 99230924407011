import { clamp } from '@styles/utils/conversion.style'
import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'

export const SocialsMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => (props.theme === 'dark' ? colors.dark : colors.light)};
`

export const SocialsItem = styled.a`
  display: block;
  width: 3.2rem;
  ${clamp('width', 24, 32)}
  aspect-ratio: 1;

  & + & {
    ${clamp('margin-left', 24, 32)}
  }
`
