import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import FontFaceObserver from 'fontfaceobserver'

// Components
import {
  useStore,
  updatePathname,
  updatePopState,
  loadFonts,
} from '../../Store'
import TransitionMask from '../PageTransitionMask'
import Gridlines from '../Gridlines'
import Header from '../Header'
// import CookieBar from '../CookieBar'

// Fonts
import ALoveOfThunderWOFF from '../../fonts/ALoveOfThunder/a_love_of_thunder-webfont.woff'
import ALoveOfThunderWOFF2 from '../../fonts/ALoveOfThunder/a_love_of_thunder-webfont.woff2'

// Styles
import { GlobalStyles } from '../../styles/GlobalStyles.style'
import { font } from '../../styles/vars/font.style'

const Layout = ({ children, location }) => {
  const [, dispatch] = useStore()

  const handleBrowserNavigationInteraction = useCallback(
    e => {
      updatePathname(dispatch, window.location.pathname)
      updatePopState(dispatch)
    },
    [dispatch]
  )

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const handleWebfontLoad = useCallback(() => {
    const observers = []
    const fontData = {
      [font.primary.family]: { weight: font.primary.weight.regular },
      [font.primary.family]: { weight: font.primary.weight.bold },
      [font.secondary.family]: { weight: font.secondary.weight.regular },
    }

    Object.keys(fontData).forEach(family => {
      const data = fontData[family]
      const obs = new FontFaceObserver(family, data)
      observers.push(obs.load())
    })

    Promise.all(observers)
      .then(() => {
        loadFonts(dispatch)
      })
      .catch(err => {
        console.warn('Some critical font are not available', err)
      })
  }, [dispatch])

  useEffect(() => {
    setViewportHeight()
    handleWebfontLoad()

    window.addEventListener('resize', setViewportHeight)
    window.addEventListener('popstate', handleBrowserNavigationInteraction)

    return () => {
      window.removeEventListener('resize', setViewportHeight)
      window.removeEventListener('popstate', handleBrowserNavigationInteraction)
    }
  }, [dispatch, handleWebfontLoad, handleBrowserNavigationInteraction])

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <style>{`
          @font-face {
            font-family: ${font.secondary.family};
            src: url(${ALoveOfThunderWOFF}) format('woff'),
              url(${ALoveOfThunderWOFF2}) format('woff2');
            font-weight: ${font.secondary.weight.regular};
          }
        `}</style>
      </Helmet>

      <Gridlines show={false} />

      <TransitionMask />

      <div id="scroll-container" data-scroll-container>
        <Header pathname={location.pathname} />

        {children}

        {/* <CookieBar /> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
