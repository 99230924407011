import React from 'react'

const LogoInstagram = ({
  width = 32,
  height = 32,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-label="Instagram"
      focusable="false"
      {...svgAttributes}
    >
      <path
        d="M16 2.881c4.275 0 4.781.019 6.462.094 1.563.069 2.407.331 2.97.55a4.949 4.949 0 0 1 1.837 1.194 4.921 4.921 0 0 1 1.194 1.837c.218.563.48 1.413.55 2.969.074 1.688.093 2.194.093 6.463 0 4.274-.019 4.78-.094 6.462-.068 1.563-.33 2.406-.55 2.969a4.95 4.95 0 0 1-1.193 1.837 4.922 4.922 0 0 1-1.838 1.194c-.562.219-1.412.481-2.969.55-1.687.075-2.193.094-6.462.094-4.275 0-4.781-.019-6.463-.094-1.562-.069-2.406-.331-2.968-.55a4.949 4.949 0 0 1-1.838-1.194 4.922 4.922 0 0 1-1.193-1.837c-.22-.563-.482-1.413-.55-2.969-.075-1.688-.094-2.194-.094-6.462 0-4.275.018-4.782.093-6.463.07-1.563.332-2.406.55-2.969A4.948 4.948 0 0 1 4.732 4.72 4.921 4.921 0 0 1 6.57 3.525c.562-.219 1.412-.481 2.968-.55C11.22 2.9 11.726 2.881 16 2.881ZM16 0c-4.344 0-4.887.019-6.594.094-1.7.075-2.869.35-3.881.744-1.056.412-1.95.956-2.838 1.85-.893.887-1.437 1.78-1.85 2.83C.444 6.539.17 7.7.094 9.4.019 11.113 0 11.656 0 16c0 4.344.019 4.887.094 6.594.075 1.7.35 2.868.744 3.881.412 1.056.956 1.95 1.85 2.837a7.82 7.82 0 0 0 2.83 1.844c1.02.394 2.182.669 3.882.744 1.706.075 2.25.094 6.594.094 4.343 0 4.887-.019 6.593-.094 1.7-.075 2.87-.35 3.882-.744a7.82 7.82 0 0 0 2.831-1.843 7.822 7.822 0 0 0 1.844-2.832c.394-1.019.669-2.181.744-3.881.074-1.706.093-2.25.093-6.594s-.019-4.887-.094-6.594c-.075-1.7-.35-2.868-.743-3.88a7.494 7.494 0 0 0-1.831-2.845A7.82 7.82 0 0 0 26.48.845C25.462.45 24.3.175 22.6.1 20.887.019 20.344 0 16 0Z"
        fill={fill}
      />
      <path
        d="M16 7.781A8.22 8.22 0 0 0 7.781 16 8.22 8.22 0 0 0 16 24.219 8.22 8.22 0 0 0 24.219 16 8.22 8.22 0 0 0 16 7.781Zm0 13.55a5.332 5.332 0 1 1 .001-10.664A5.332 5.332 0 0 1 16 21.331ZM26.462 7.456a1.92 1.92 0 1 1-3.838 0 1.92 1.92 0 0 1 3.838 0Z"
        fill={fill}
      />
    </svg>
  )
}

export default LogoInstagram
