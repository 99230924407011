import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { hideMobileNav, hidePageMask, useStore } from '../../Store'
import { TransitionMaskWrapper, TransitionMaskBlock } from './index.style'
import { animation } from '../../styles/vars/animation.style'

const TransitionMask = () => {
  const [store, dispatch] = useStore()
  const maskRef = useRef()

  useEffect(() => {
    const tl = gsap.timeline()

    if (store.showPageMask) {
      const mask = maskRef.current

      tl.to(mask, {
        opacity: 1,
        duration: animation.maskShowDuration,
        ease: 'power2.inOut',
        onComplete: () => hideMobileNav(dispatch),
      }).to(mask, {
        opacity: 0,
        duration: animation.maskHideDuration,
        ease: 'power2.inOut',
        onComplete: () => hidePageMask(dispatch),
      })
    }

    return () => {
      if (tl) {
        tl.kill()
      }
    }
  }, [store.showPageMask, dispatch])

  return (
    <TransitionMaskWrapper show={store.showPageMask}>
      <TransitionMaskBlock ref={maskRef} />
    </TransitionMaskWrapper>
  )
}

export default TransitionMask
