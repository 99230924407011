import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { mq } from '../../styles/vars/media-queries.style'

export const MenuToggleWrapper = styled.div`
  position: relative;
  right: -0.8rem;
  z-index: ${zIndex.mobileNavButtons};
`

export const MenuToggleMain = styled.button`
  position: relative;
  width: 4.4rem;
  height: 4.4rem;
  background-color: transparent;
  border: none;

  ${mq.headerNav} {
    display: none;
  }
`

export const MenuToggleBar = styled.div`
  position: absolute;
  top: calc(50% - 0.15rem);
  left: calc(50% - 1.4rem);
  width: 2.8rem;
  height: 0.3rem;
  background-color: ${colors.dark};
  border-radius: 0.2rem;

  :first-child {
    transform: translateY(-0.8rem);
  }

  :last-child {
    transform: translateY(0.8rem);
  }
`
