import styled from 'styled-components'
import { transparentize } from 'polished'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'
import { easings } from '../../styles/vars/easings.style'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'
import Logo from '../../images/logo.png'
import { font } from '../../styles/vars/font.style'
import { SocialsMain } from '../Socials/index.style'
import { matchContainerPadding, paddingMax } from '../Container/index.style'
import { Heading4 } from '@styles/vars/textStyles.style'

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};
  background-color: ${colors.light};
`

export const HeaderWrapper = styled.div`
  position: relative;
  z-index: ${zIndex.header};
  background-color: ${colors.light};
  box-shadow: 0 0 0.4rem 0 ${transparentize(0.9, colors.dark)};
`

export const HeaderMain = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${mq.headerNavMax} {
    ${clamp('padding-top', 10, 12, breakpoints.mobile, breakpoints.headerNav)}
    ${clamp(
      'padding-bottom',
      10,
      12,
      breakpoints.mobile,
      breakpoints.headerNav
    )}
  }

  ${mq.headerNav} {
    justify-content: space-between;
  }
`

export const HeaderLogo = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(-50%);
  ${clamp('width', 112, 128)}
  aspect-ratio: 1;
  z-index: ${zIndex.mobileNavButtons};
  background-image: url(${Logo});
  background-position: center;
  background-size: contain;

  ${PageTransitionLinkMain} {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    border-radius: 50%;
  }
`

export const HeaderNav = styled.nav`
  display: none;

  ${mq.headerNav} {
    display: flex;
    align-items: center;
  }
`

export const HeaderMenu = styled.ul`
  display: none;

  ${mq.headerNav} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    text-align: right;
  }
`

export const HeaderMenuItem = styled.li`
  ${PageTransitionLinkMain} {
    display: inline-flex;
    ${clamp('padding-top', 14, 28, breakpoints.mobile, breakpoints.headerNav)}
    ${clamp(
      'padding-bottom',
      14,
      28,
      breakpoints.mobile,
      breakpoints.headerNav
    )}
    ${clamp('padding-right', 12, 16)}
    ${clamp('padding-left', 12, 16)}
    background-color: ${props => (props.active ? colors.cream : 'transparent')};
    transition: background-color 0.2s ${easings.inOut.default};

    :hover {
      background-color: ${colors.cream};
    }
  }

  ${Heading4} {
    line-height: 1;
  }
`

export const HeaderDivider = styled.div`
  width: 0.1rem;
  height: 5.6rem;
  ${clamp('margin-right', 24, 32)}
  ${clamp('margin-left', 12, 16)}
  background-color: ${colors.dark};
`

export const MobileNavOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: ${zIndex.mobileNavOverlay};
  background-color: ${colors.dark};
  opacity: ${props => (props.open ? 0.8 : 0)};
  transition: opacity 0.3s ${easings.inOut.default};
`

export const MobileNavMain = styled.nav``

export const MobileNav = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: ${props => (props.open ? 'translateY(100%)' : 'translateY(0%)')};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: ${zIndex.mobileNav};
  padding-top: 6.4rem;
  padding-bottom: 4.8rem;
  text-align: center;
  background-color: ${colors.yellow};
  transition: transform 0.3s ${easings.inOut.default};

  ${mq.headerNav} {
    transform: translateY(0%);
  }

  ${MobileNavMain},
  ${SocialsMain} {
    pointer-events: ${props => (props.open ? undefined : 'none')};
  }
`

export const MobileMenu = styled.ul`
  list-style: none;
`

export const MobileMenuItem = styled.li`
  & + & {
    margin-top: 2.4rem;
  }

  ${PageTransitionLinkMain} {
    display: block;
    width: 100%;
    text-align: center;
  }
`

export const Donate = styled.a`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  align-items: center;
  padding: 0.8rem 1.6rem;
  text-decoration: none;
  text-align: center;
  background-color: ${colors.dark};

  ${mq.headerNav} {
    ${matchContainerPadding('right')}
  }

  @media (min-width: ${breakpoints.contained + paddingMax * 2}px) {
    right: calc(50% - ${breakpoints.contained / 2}px);
  }

  ::before {
    content: '';
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    display: block;
    height: 0.8rem;
    background-image: url("data:image/svg+xml,%3Csvg width='171' height='8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M171 7.15V0H0v5.735c.098-.003.199-.005.303-.005 1.577 0 2.364-.016 3.152-.032.789-.015 1.577-.031 3.156-.031 1.58 0 2.368-.229 3.156-.457.788-.228 1.576-.457 3.153-.457s2.365.19 3.154.38c.788.19 1.577.38 3.154.38s2.366-.061 3.154-.123a34.7 34.7 0 0 1 3.155-.123c1.577 0 2.365-.051 3.154-.102.788-.05 1.577-.102 3.154-.102S34.211 5.03 35 4.995a62.49 62.49 0 0 1 3.154-.068c1.576 0 2.364.268 3.153.536.79.268 1.58.537 3.163.537 1.583 0 2.371.388 3.16.776.786.387 1.572.774 3.148.774 1.575 0 2.358.112 3.142.225.785.112 1.571.225 3.157.225 1.585 0 2.373-.835 3.161-1.67.786-.832 1.572-1.663 3.147-1.663 1.577 0 2.365.19 3.153.381.787.191 1.575.382 3.152.382 1.577 0 2.366.422 3.155.843.788.422 1.577.844 3.154.844s2.365-.095 3.154-.19c.788-.095 1.577-.19 3.154-.19s2.366-.334 3.154-.667c.789-.333 1.578-.667 3.155-.667 1.577 0 2.366-.071 3.154-.143.788-.072 1.576-.143 3.15-.143 1.576 0 2.364-.02 3.153-.039.79-.019 1.579-.038 3.156-.038 1.577 0 2.366-.127 3.154-.253.789-.127 1.578-.254 3.155-.254 1.577 0 2.365.4 3.152.799.788.399 1.576.798 3.153.798s2.366-.303 3.154-.607c.789-.303 1.577-.606 3.154-.606s2.366.687 3.155 1.375c.788.687 1.577 1.375 3.154 1.375s2.365-.138 3.152-.275c.788-.138 1.576-.275 3.153-.275s2.366.075 3.154.15c.789.075 1.577.15 3.154.15 1.578 0 2.365-.48 3.153-.959.788-.479 1.575-.958 3.153-.958 1.577 0 2.365.358 3.154.717.788.358 1.577.716 3.154.716s2.365-.54 3.153-1.08c.787-.54 1.575-1.08 3.152-1.08 1.577 0 2.366.352 3.154.704.789.351 1.578.703 3.155.703 1.577 0 2.365.243 3.152.485.788.242 1.576.485 3.153.485H171Z' fill='%231E1E1E'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: center top;
  }
`

export const DonateText = styled.span`
  margin-right: 0.8rem;
  font-family: ${font.secondary.family};
  font-size: 2.4rem;
  line-height: 1.3333;
  color: ${colors.light};
  text-transform: uppercase;
`
