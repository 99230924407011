import React from 'react'

const LogoTwitter = ({
  width = 32,
  height = 32,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      aria-label="Twitter"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M10.067 29c12.072 0 18.677-10.004 18.677-18.678 0-.28-.006-.568-.018-.85A13.333 13.333 0 0 0 32 6.074c-1.197.532-2.467.88-3.768 1.031a6.6 6.6 0 0 0 2.887-3.63 13.196 13.196 0 0 1-4.168 1.592 6.574 6.574 0 0 0-11.192 5.987A18.646 18.646 0 0 1 2.231 4.199a6.573 6.573 0 0 0 2.03 8.761 6.573 6.573 0 0 1-2.974-.819v.082a6.563 6.563 0 0 0 5.268 6.437 6.525 6.525 0 0 1-2.962.113 6.578 6.578 0 0 0 6.13 4.562A13.167 13.167 0 0 1 0 26.051 18.626 18.626 0 0 0 10.067 29Z"
      />
    </svg>
  )
}

export default LogoTwitter
