export const font = {
  primary: {
    family: 'Noto Sans',
    weight: {
      regular: 400,
      bold: 700,
    },
  },
  secondary: {
    family: 'A Love Of Thunder',
    weight: {
      regular: 400,
    },
  },
  fallback: {
    family: 'sans-serif',
  },
}
